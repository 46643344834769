import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import mainPageEvent, {
  EVENT_CONTACT_US_CLICKED,
  EVENT_FEATURES_CLICKED,
  EVENT_STATS_CLICKED,
} from "../../services/analytics";

export default function MainNavbar(props) {
  return (
    <Navbar className="navbar" bg="light" expanded="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/#">Reslico</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => {
                mainPageEvent(EVENT_FEATURES_CLICKED);
              }}
              href="/#features"
            >
              Features
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                mainPageEvent(EVENT_STATS_CLICKED);
              }}
              href="/#samples"
            >
              Samples
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                mainPageEvent(EVENT_CONTACT_US_CLICKED);
              }}
              href="/#contact_us"
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
