import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Check2Circle, ExclamationCircle } from "react-bootstrap-icons";

import ReslicoApi from "../../services/network/api";
import mainPageEvent, {
  EVENT_CONTACT_US_SUBMIT,
} from "../../services/analytics";
const reslicoApi = new ReslicoApi();

const FormStatus = {
  Ready: "ready",
  Processing: "processing",
  Success: "success",
  FailedGeneral: "failed - general error",
  FailedInvalidMail: "failed - invalid email address of recipient",
};

export default function ContactUs(props) {
  const [validated, setValidated] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [status, setStatus] = useState(FormStatus.Ready);

  const onSubmit = async (e) => {
    mainPageEvent(EVENT_CONTACT_US_SUBMIT, JSON.stringify(formValues));
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity() === false) {
    } else {
      setStatus(FormStatus.Processing);
      reslicoApi
        .postContactUsForm({
          name: formValues.full_name,
          email: formValues.email,
          message: formValues.message,
        })
        .then((response) => {
          setStatus(FormStatus.Success);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            setStatus(FormStatus.FailedInvalidMail);
          } else {
            setStatus(FormStatus.FailedGeneral);
          }
        });
    }
    setValidated(true);
  };

  const idle = (
    <div className="row my-5">
      <p>
        Please submit your details and we will get back to you with more info:
      </p>
      <Form noValidate validated={validated} onSubmit={onSubmit}>
        <Form.Group controlId="form.Name" className="my-3">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter name"
            onChange={(e) =>
              setFormValues({ ...formValues, full_name: e.target.value })
            }
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid name
          </Form.Control.Feedback>
          <Form.Control.Feedback>Great!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="form.Email" className="my-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="name@example.com"
            onChange={(e) =>
              setFormValues({ ...formValues, email: e.target.value })
            }
          />
          <Form.Control.Feedback>Excellent!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please provide a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="form.Textarea" className="my-3">
          <Form.Label>Message (optional)</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            onChange={(e) =>
              setFormValues({ ...formValues, message: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Check
            required
            label="I Agree to receive mails from Reslico.com"
            feedback="You must agree before submitting."
            feedbackType="invalid"
          />
        </Form.Group>
        <Button type="submit" className="btn btn-primary my-3">
          Submit
        </Button>
      </Form>
    </div>
  );

  const processing = (
    <div className="row text-center mb-5">
      <h2 className="mx-4">
        Processing form, please wait...
        <Spinner
          //
          className="mx-4"
          animation="border"
          variant="dark"
        />
      </h2>
    </div>
  );

  const success = (
    <div className="row text-center mb-5">
      <h2>
        <Check2Circle className="mx-2" color="green" />
        Form processed successfully, Thank you!
      </h2>
    </div>
  );

  const failedGeneral = (
    <div className="row text-center mb-5">
      <h2>
        <ExclamationCircle className="mx-2" color="red" />
        Failed to process form, please reload and try again
        {/* <Button
          className="mx-2"
          color="light"
          onClick={() => setStatus(FormStatus.Ready)}
        >
          Retry
        </Button> */}
      </h2>
    </div>
  );

  const failedInvalidMail = (
    <div className="row text-center mb-5">
      <h2>
        <ExclamationCircle className="mx-2" color="red" />
        Email address is invalid, please check and try again
        {/* <Button
          className="mx-2"
          color="light"
          onClick={() => setStatus(FormStatus.Ready)}
        >
          Retry
        </Button> */}
      </h2>
    </div>
  );

  let selectedView = idle;
  switch (status) {
    case FormStatus.Processing: {
      selectedView = processing;
      break;
    }
    case FormStatus.Success: {
      selectedView = success;
      break;
    }
    case FormStatus.FailedGeneral: {
      selectedView = failedGeneral;
      break;
    }
    case FormStatus.FailedInvalidMail: {
      selectedView = failedInvalidMail;
      break;
    }
    default: {
    }
  }

  return (
    <section className="contact_us min-h-400" {...props}>
      <div className="container">
        <div className="row text-center my-5">
          <h1>Contact us</h1>
        </div>
        <div className="row my-5">{selectedView}</div>
      </div>
    </section>
  );
}
