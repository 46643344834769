import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableDataCell,
  CTableBody,
} from "@coreui/react";
import { Button, Container } from "react-bootstrap";
import React from "react";
import FlagsSvgs from "../../utils/FlagsSvgs";
import CIcon from "@coreui/icons-react";
import { sampleDownloadedEvent } from "../../services/analytics";

export default function Stats(props) {
  const countriesData = [
    {
      country: "France",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_France.csv",
      rent: "",
    },
    {
      country: "Germany",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_Germany.csv",
      rent: "",
    },
    {
      country: "Israel",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_Israel.csv",
      rent: "",
    },
    {
      country: "Italy",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_Italy.csv",
      rent: "",
    },
    {
      country: "Portugal",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_Portugal.csv",
      rent: "",
    },
    {
      country: "Spain",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_Spain.csv",
      rent: "",
    },

    {
      country: "United Kingdom",
      for_sale:
        "https://reslico-samples.s3.eu-central-1.amazonaws.com/sale/ForSale_UnitedKingdom.csv",
      rent: "",
    },
  ];

  const entityRenderer = (x) => {
    return (
      <>
        <CTableDataCell className="text-start">
          <CIcon size="xl" icon={FlagsSvgs[x]} />
        </CTableDataCell>
        <CTableDataCell className="text-start">
          <strong>{x}</strong>
        </CTableDataCell>
      </>
    );
  };

  return (
    <>
      <section className="samples" {...props}>
        <Container fluid>
          <div className="container text-center">
            <div className="row align-items-center text-center my-5 py-5">
              <div className="col-md-12">
                <h1>Samples</h1>
                <CTable striped className="mt-3">
                  <CTableHead>
                    <CTableRow>
                      <CTableHeaderCell></CTableHeaderCell>
                      <CTableHeaderCell
                        className="text-start"
                        scope="col"
                        //   colSpan={2}
                      >
                        Country
                      </CTableHeaderCell>
                      <CTableHeaderCell scope="col">For Sale</CTableHeaderCell>
                      <CTableHeaderCell scope="col">For Rent</CTableHeaderCell>
                    </CTableRow>
                  </CTableHead>
                  <CTableBody>
                    {countriesData.map((entityObj) => (
                      <CTableRow key={entityObj.country}>
                        {entityRenderer(entityObj.country)}
                        {/* <CTableDataCell className="text-start">
                        <strong>{entityObj.country}</strong>
                      </CTableDataCell> */}
                        <CTableDataCell>
                          {entityObj.for_sale ? (
                            <Button
                              className="btn btn-primary px-5"
                              href={`${entityObj.for_sale}`}
                              onClick={() =>
                                sampleDownloadedEvent(entityObj.country)
                              }
                            >
                              <strong>Download</strong>
                            </Button>
                          ) : null}
                        </CTableDataCell>
                        <CTableDataCell>
                          {entityObj.rent ? (
                            <a href={`${entityObj.rent}`}>Download</a>
                          ) : null}
                        </CTableDataCell>
                      </CTableRow>
                    ))}
                  </CTableBody>
                </CTable>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}
